import {
  Environment,
  mapTilerKey,
  prodAgoraAppId,
  prodApiUrl,
  prodFirebaseConfig,
  prodFirebaseTokenId,
  prodFirebaseVapidKey,
  prodWsDomain,
  prodSupersetDomain,
  prodSupersetDashboardIds,
} from ".";

export const environment: Environment = {
  production: true,
  firebaseConfig: prodFirebaseConfig,
  firebaseVapidKey: prodFirebaseVapidKey,
  sentryDsn:
    "https://12fd260d42b8eef1b61f92ec6fbf31cb@o1211156.ingest.us.sentry.io/4506969804767232",
  sentryWhitelistHosts: [
    "web.jugl.com",
    /jugl-prod-org-web-rc-\d+-\d+-\d+.netlify.app/g,
  ],
  apiUrl: prodApiUrl,
  wsDomain: prodWsDomain,
  firebaseTokenId: prodFirebaseTokenId,
  mapTilerKey,
  geocodingKey: "AIzaSyDQA0ddPIu0a0po7SryyTQKsF1aZCYi0y8",
  agoraAppId: prodAgoraAppId,
  intercomAppId: "d8ychfjy",
  supersetDomain: prodSupersetDomain,
  supersetDashboardIds: prodSupersetDashboardIds,
  supersetChartIdToType: { 114: "tasks", 117: "people", 122: "travelLog" },
  isReCaptchaEnabled: true,
  reCaptchaKey: "6LdMnvopAAAAAID5SLUbjhOfVLc2BDzMlvJPr3nn",
  guestUrl: "https://guest.jugl.com",
};
