import {
  DialogPanel as HeadlessUIDialogPanel,
  Dialog as HeadlessUIDialog,
  DialogProps as HeadlessUIDialogProps,
  Transition,
  TransitionChild,
  DialogBackdrop,
} from "@headlessui/react";
import {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { cx, useAppVariant } from "@jugl-web/utils";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { Button, ButtonProps } from "../Button";
import { InteractiveContainer } from "../InteractiveContainer";

export interface AlertProps {
  title?: string;
  header?: string;
  content?: ReactNode | string;
  isCloseButtonVisible?: boolean;
  headerRightContent?: ReactNode;
  isOpen?: boolean;
  initialFocus?: HeadlessUIDialogProps["initialFocus"];
  onRequestClose?: () => void;
  img?: ReactNode | string;
  trigger?: string;
  disableBackdropClose?: boolean;
  className?: string;
  buttonsAlignment?: "horizontal" | "vertical";
  buttonsContainerClassName?: string;
  buttons?: Array<
    Pick<ButtonProps, "variant" | "color" | "isDisabled" | "className"> & {
      text: string;
      role?: "close";
      onClick?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        actions: { closeAlert: () => void }
      ) => void;
    }
  >;
}

export const Alert: FC<AlertProps> = ({
  title,
  header,
  isOpen,
  initialFocus,
  img,
  trigger,
  content,
  buttonsAlignment = "horizontal",
  buttonsContainerClassName,
  buttons,
  onRequestClose,
  disableBackdropClose,
  className,
  isCloseButtonVisible,
  headerRightContent,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState<boolean>(false);
  const { variant } = useAppVariant();
  const isDialogOpen = isOpen || internalIsOpen || false;
  useEffect(() => {
    if (!trigger) {
      return () => {};
    }
    const $trigger = document.getElementById(trigger);
    if (!$trigger) {
      return () => {};
    }
    const clickEvent = () => setInternalIsOpen(true);
    $trigger.addEventListener("click", clickEvent);
    return () => {
      $trigger.removeEventListener("click", clickEvent);
    };
  }, [trigger]);

  const handleDialogOnClose = useCallback(() => {
    onRequestClose?.();
    if (internalIsOpen) {
      setInternalIsOpen(false);
    }
  }, [internalIsOpen, onRequestClose]);

  return (
    <Transition show={isDialogOpen} as={Fragment}>
      <HeadlessUIDialog
        initialFocus={initialFocus}
        onClose={!disableBackdropClose ? handleDialogOnClose : () => null}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 z-50 bg-[rgba(14,14,14,0.66)] backdrop-blur-[1px]" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="transition duration-200"
          enterFrom="opacity-0 scale-90"
          enterTo="opacity-100 scale-100"
          leave="transition duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-90"
        >
          <HeadlessUIDialogPanel
            className={cx(
              "fixed left-1/2 top-1/2 z-[2000] flex max-h-[90vh] -translate-x-1/2 -translate-y-1/2 flex-col rounded-2xl bg-white",
              variant === "web" ? "w-[480px]" : "w-[90%]",
              className
            )}
          >
            {(isCloseButtonVisible || header) && (
              <div className="flex min-h-[60px] items-center justify-between border-x-0 border-b border-t-0 border-solid border-b-gray-100 px-6 py-[14px]">
                {header && (
                  <span className="text-sm font-semibold text-[#383838]">
                    {header}
                  </span>
                )}
                <div className="row flex">
                  {headerRightContent}
                  {isCloseButtonVisible && (
                    <InteractiveContainer
                      className="ml-auto h-8 w-8"
                      onClick={handleDialogOnClose}
                    >
                      <CrossIcon />
                    </InteractiveContainer>
                  )}
                </div>
              </div>
            )}
            <div className="flex-1 overflow-y-auto p-10">
              {img && (
                <div className="mb-6 flex justify-center">
                  {typeof img === "string" ? (
                    <img src={img} alt={title} />
                  ) : (
                    img
                  )}
                </div>
              )}
              <div className="flex flex-col gap-[18px]">
                {title && (
                  <div className="text-center text-xl font-medium">{title}</div>
                )}
                {content && (
                  <div className="text-center text-base leading-[140%] tracking-[0.16px] text-[#40494D]">
                    {content}
                  </div>
                )}
              </div>
              {buttons && buttons.length > 0 && (
                <div
                  className={cx(
                    "mt-14 flex gap-3",
                    buttonsAlignment === "horizontal"
                      ? "items-center gap-3 [&>button]:flex-1"
                      : "flex-col gap-6 [&>button]:w-full",
                    buttonsContainerClassName
                  )}
                >
                  {buttons.map(({ text, role, ...buttonProps }, idx) => (
                    <Button
                      key={+idx}
                      {...{
                        color: role === "close" ? "grey" : undefined,
                        ...buttonProps,
                        onClick: (e) => {
                          if (buttonProps?.onClick) {
                            buttonProps?.onClick?.(e, {
                              closeAlert: handleDialogOnClose,
                            });
                            return;
                          }
                          if (role === "close") {
                            handleDialogOnClose();
                          }
                        },
                      }}
                    >
                      {text}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </HeadlessUIDialogPanel>
        </TransitionChild>
      </HeadlessUIDialog>
    </Transition>
  );
};
