import {
  getTasksSourceInfo,
  TaskCalendarView,
  TasksSourceInfo,
} from "@jugl-web/domain-resources/tasks";
import { TaskPropertiesPanelConfig } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel/types";
import { TaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useTaskListPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskListPreferences";
import { PreviewTask, TasksSource } from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

export type ViewMode =
  | "date-view"
  | "labels-view"
  | "priority-view"
  | "status-view"
  | "custom-field-view";

export type SupportedTaskPropertiesPanelConfigOverrides = {
  dueDate?: Partial<TaskPropertiesPanelConfig["dueDate"]>;
  assignees?: Partial<TaskPropertiesPanelConfig["assignees"]>;
};

export interface NewTaskDialogState {
  isOpen: boolean;
  initialFormState?: Partial<TaskFormState>;
  taskPropertiesPanelConfigOverrides?: SupportedTaskPropertiesPanelConfigOverrides;
}

interface FutureTaskDialogState {
  isOpen: boolean;
  task: PreviewTask | null;
}

export type TaskListLayout = "kanban" | "table" | "calendar";

interface TasksPageContextValue {
  newTaskDialogState: NewTaskDialogState;
  futureTaskDialogState: FutureTaskDialogState;
  tasksSource: TasksSource;
  tasksSourceInfo: TasksSourceInfo;
  taskListLayout: TaskListLayout;
  taskCalendarView: TaskCalendarView;
  taskCalendarDate: Date;
  setTaskListLayout: (layout: TaskListLayout) => void;
  setTaskCalendarView: (view: TaskCalendarView) => void;
  setTaskCalendarDate: (date: Date) => void;
  navigateToTaskDetailsPage: (taskId: string) => void;
  openNewTaskDialog: (state: Omit<NewTaskDialogState, "isOpen">) => void;
  openFutureTaskDialog: (task: PreviewTask) => void;
  closeNewTaskDialog: () => void;
  closeFutureTaskDialog: () => void;
}

export const TasksPageContext = createContext<TasksPageContextValue | null>(
  null
);

interface TasksPageContextProviderProps {
  children: ReactNode;
}

export const TasksPageContextProvider: FC<TasksPageContextProviderProps> = ({
  children,
}) => {
  const { boardId, customerId } = useParams();

  const tasksSource = useMemo<TasksSource>(() => {
    if (customerId) {
      return { type: "customerTasks", customerId };
    }

    return { type: "boardTasks", boardId: boardId || "my" };
  }, [boardId, customerId]);

  const tasksSourceInfo = useMemo<TasksSourceInfo>(
    () => getTasksSourceInfo(tasksSource),
    [tasksSource]
  );

  const { navigateToPage } = useNavigation();

  const [newTaskDialogState, setNewTaskDialogState] =
    useState<NewTaskDialogState>({ isOpen: false });

  const [futureTaskDialogState, setFutureTaskDialogState] =
    useState<FutureTaskDialogState>({ isOpen: false, task: null });

  const { entityId } = useEntitySelectedProvider();

  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({
      entityId,
      source: tasksSource,
    });

  const taskListLayout = taskListPreferences.layout;

  const setTaskListLayout = useCallback(
    (layout: TaskListLayout) => updateTaskListPreference("layout", layout),
    [updateTaskListPreference]
  );

  const taskCalendarView = taskListPreferences.calendarView;

  const setTaskCalendarView = useCallback(
    (view: TaskCalendarView) => updateTaskListPreference("calendarView", view),
    [updateTaskListPreference]
  );

  const sourceBasedTaskCalendarView = useMemo<TaskCalendarView>(() => {
    if (tasksSourceInfo.isTeamTasks) {
      return "week";
    }

    return taskCalendarView;
  }, [taskCalendarView, tasksSourceInfo.isTeamTasks]);

  const taskCalendarDate = useMemo(
    () => new Date(taskListPreferences.calendarDate),
    [taskListPreferences.calendarDate]
  );

  const setTaskCalendarDate = useCallback(
    (date: Date) => updateTaskListPreference("calendarDate", date.toJSON()),
    [updateTaskListPreference]
  );

  const navigateToTaskDetailsPage = useCallback(
    (taskId: string) => {
      if (tasksSource.type === "customerTasks") {
        navigateToPage(
          "customersTasksDetails",
          { customerId: tasksSource.customerId, taskId },
          { state: { source: tasksSource } }
        );
      } else {
        navigateToPage(
          "tasksDetails",
          { taskId },
          { state: { source: tasksSource } }
        );
      }
    },
    [navigateToPage, tasksSource]
  );

  const openNewTaskDialog = (state: Omit<NewTaskDialogState, "isOpen">) => {
    setNewTaskDialogState({ isOpen: true, ...state });
  };

  const closeNewTaskDialog = () => {
    setNewTaskDialogState((previousState) => ({
      ...previousState,
      isOpen: false,
    }));
  };

  const openFutureTaskDialog = (task: PreviewTask) => {
    setFutureTaskDialogState({ isOpen: true, task });
  };

  const closeFutureTaskDialog = () => {
    setFutureTaskDialogState((previousState) => ({
      ...previousState,
      isOpen: false,
    }));
  };

  const contextValue = useMemo<TasksPageContextValue>(
    () => ({
      newTaskDialogState,
      futureTaskDialogState,
      tasksSource,
      tasksSourceInfo,
      taskListLayout,
      taskCalendarView: sourceBasedTaskCalendarView,
      taskCalendarDate,
      navigateToTaskDetailsPage,
      setTaskListLayout,
      setTaskCalendarView,
      setTaskCalendarDate,
      openNewTaskDialog,
      openFutureTaskDialog,
      closeNewTaskDialog,
      closeFutureTaskDialog,
    }),
    [
      newTaskDialogState,
      futureTaskDialogState,
      tasksSource,
      tasksSourceInfo,
      taskListLayout,
      sourceBasedTaskCalendarView,
      taskCalendarDate,
      navigateToTaskDetailsPage,
      setTaskListLayout,
      setTaskCalendarView,
      setTaskCalendarDate,
    ]
  );

  return (
    <TasksPageContext.Provider value={contextValue}>
      {children}
    </TasksPageContext.Provider>
  );
};

export const useTasksPageContext = () => {
  const context = useContext(TasksPageContext);

  if (!context) {
    throw new HookOutOfContextError("useTasksPageContext", "TasksPageContext");
  }

  return context;
};
